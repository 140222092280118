<template>
	<edit-template class="role-edit" ref="edit" @confirm="save('form')" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">

		<!-- 大表单 -->
		<div class="form-body">
			<el-form ref="form" :model="form" :rules="rules" label-width="150rem">
				<el-form-item label="行政班级类型名称" prop="name">
					<el-input v-model="form.name" placeholder="请输入对应行政班级类型名称" style="width: 215rem"></el-input>
				</el-form-item>

				<el-form-item label="适用校区" prop="school_ids">
					<el-button plain="" @click="choose">点击选择</el-button>
					<br>
					<div v-if="checkList.length > 0">
						<el-tag @close="handleClose(item)" :closable="true" v-for="item in checkList" :key="item.id" style="margin-left: 10rem">{{
								item.school_name
							}}</el-tag>
						<!--						<el-input v-model="form.school_ids" v-show="false"></el-input>-->
						<el-checkbox-group v-model="form.school_ids" v-show="false">
							<el-checkbox label="适用校区" name="type"></el-checkbox>
						</el-checkbox-group>
					</div>
				</el-form-item>
			</el-form>
			<!-- 面板展示 -->
			<el-descriptions class="margin-top" :column="3" border="" style="minwidth: 500rem; margin: 50rem 50rem 50rem 50rem">
				<el-descriptions-item style="width: 150rem">
					<template slot="label">创建人:</template>
					{{ form.creator == null ? '' : form.creator }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">创建时间:</template>
					{{ form.create_time == null ? '' : form.create_time }}
				</el-descriptions-item>
			</el-descriptions>
		</div>

		<!-- 底部按钮 -->


		<!-- 点击选择角色弹窗 -->
		<el-dialog title="选择校区" :visible.sync="dialogVisible" width="50%" :before-close="handleCloseTo">
			<el-checkbox v-model="checkAll" @change="handleCheckAllChange" border="" class="checkbox" style="overflow: hidden">全部校区</el-checkbox>
			<el-checkbox-group v-model="checkList" @change="changeList">
				<el-checkbox v-for="item in checkDataList" :label="item" border="" class="checkbox" style="overflow: hidden">{{
						item.school_name
					}}</el-checkbox>
			</el-checkbox-group>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisiblefalse">取 消</el-button>
				<el-button type="primary" @click="dialogVisibletrue">确 定</el-button>
			</span>
		</el-dialog>

</edit-template>
</template>

<script>
import { school, administrativeEdit, administrativeDetails } from '@/api/account-management'

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
		return {
			checkData: [],
			form: {
				name: '', // 科目
				school_ids: '', // 学校
				roles: []
			},
			checkAll: false,
			checkList: [],
			checkDataList: [],
			cheData: [],
			dialogVisible: false,
			schoolList: [],
			rules: {
				name: [
					{ required: true, message: '请输入科目', trigger: 'blur' },
					{ min: 1, max: 10, message: '字数限制在10字以内', trigger: 'blur' }
				],
				school_ids: [{ required: true, message: '请选择校区', trigger: 'change' }]
			}
		}
	},

	methods: {
		save(form) {
			this.$refs[form].validate(valid => {
				if (valid) {
					this.form.school_ids = this.form.school_ids.toString()
					// this.form.school_ids = Array.from(new Set(this.form.roles)).toString()
					let { create_time, ...params } = this.form
					administrativeEdit(params).then(res => {
						if (res.data.error.errorCode != 0) {
							this.$notify.error({
								title: '编辑出错',
								message: res.data.error.errorUserMsg
							})
						} else {
							this.$notify({
								title: '编辑成功',
								message: '编辑成功',
								type: 'success'
							})
							setTimeout(() => {
								this.$router.back()
							}, 0)
						}
					})
				} else {
					if (this.form.name.length > 10) {
						this.$message.error('字数限制在10字以内')
					} else this.$message.error('请完善您的信息')
				}
			})
		},
		open() {
			this.$confirm('是否取消编辑', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => {
				this.$router.back()
			})
			.catch(() => {})
		},
		choose() {
			this.dialogVisible = true
			this.checkData = this.checkList
			if (this.checkList.length == this.checkDataList.length) {
				this.checkAll = true
			} else {
				this.checkAll = false
			}
		},
		changeList() {
			if (this.checkList.length == this.checkDataList.length) {
				this.checkAll = true
			} else {
				this.checkAll = false
			}
		},
		handleCloseTo() {
			this.dialogVisiblefalse()
		},
		// 取消按钮
		dialogVisiblefalse() {
			this.checkList = this.checkData
			this.dialogVisible = false
		},
		// 确定按钮
		dialogVisibletrue() {
			if (this.checkList.length > 0) {
				this.form.school_ids = []
				Array.from(new Set(this.checkList)).forEach(i => {
					this.form.school_ids.push(i.id)
					Array.from(new Set(this.form.school_ids))
				})
			}
			this.dialogVisible = false
		},
		// 删除标签
		handleClose(value) {
			this.checkList.splice(this.checkList.indexOf(value), 1)
			this.form.school_ids.splice(this.form.school_ids.indexOf(value.id), 1)

		},
		handleCheckAllChange(val) {
			if (val) {
				this.checkList = []
				this.form.roles = []
				this.checkDataList.forEach(i => {
					this.checkList.push(i)
				})
			} else {
				this.checkList = []
				this.form.roles = []
				this.form.school_ids = []
			}
		}
	},

	created() {
		school().then(res => {
			this.checkDataList = res.data.data
			if (this.checkDataList.length > 0) {
				administrativeDetails(this.$route.query.id).then(res => {
					res.data.data.school_ids = res.data.data.school_ids.split(',')
					this.form = res.data.data
					this.checkDataList.forEach(i => {
						this.form.school_ids.forEach(j => {
							if (j == i.id) {
								this.checkList.push(i)
							}
						})
					})
				})
			}
		})
	}
}
</script>

<style lang="scss" scoped>
.checkbox {
	min-width: 100rem;
	margin: 10rem;
}
::v-deep .el-dialog__body {
	height: 60vh;
	overflow: auto;
}
</style>
